<template>
    <div v-if="qualifies">
        <slot></slot>
    </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { acxessPermissionSet, matchPermissionSet } from '@/models/acxess';

export default Vue.extend({
  name: 'FeatureToggle',
  props: {
    route: {
      required: false,
      type: String,
    },
    permissionSet: {
      required: false,
      type: Object as PropType<acxessPermissionSet>,
    },
    environment: {
      required: false,
      type: Array as PropType<Array<string>>,
    },
  },
  computed: {
    qualifies(): boolean {
      return this.environmentQualifies && this.setQualifies && this.routeQualifies;
    },
    routeQualifies():boolean {
      if ((this.route == null) || (this.route.length < 1)) {
        // no route restrictions
        return true;
      }
      const routes = this.$router.getRoutes();
      for (let i = 0; i < routes.length; i++) {
        if (routes[i].path === this.route) {
          return matchPermissionSet({
            productId: routes[i].meta.product,
            featureId: routes[i].meta.feature ?? null,
            accessLevels: routes[i].meta.access ?? null,
          }, this.$store);
        }
      }
      return false;
    },
    environmentQualifies():boolean {
      if (this.environment == null) {
        // no environment restrictions
        return true;
      }
      for (let i = 0; i < this.environment.length; i++) {
        if (this.environment[i] === process.env.VUE_APP_HOSTING_ENV) {
          return true;
        }
      }
      return false;
    },
    setQualifies():boolean {
      if (this.permissionSet == null) {
        // no product restrictions
        return true;
      }
      return matchPermissionSet(this.permissionSet, this.$store);
    },
  },
});
</script>
