<template>
  <v-app class="d-flex">
    <meta name="referrer" content="origin">
    <Header v-if="$route.meta.showHeader"></Header>
    <div class="d-flex flex-columns body-contain">
      <!--this is shown only on larger screens/desktop-->
      <div v-if="$route.meta.showNav && $vuetify.breakpoint.mdAndUp" class="nav-contain">
        <div v-for="route in userRoutes" :key="route.path" class="mt-2">
          <v-btn v-if="route.meta != null && route.meta.nav != null && route.meta.nav.rail != null" :to="route.path || '/'" color="#00386F" text class="nav-button mt-6" active-class="active-nav-btn">
            <v-icon large class="white--text">{{route.meta.nav.rail.icon}}</v-icon>
          </v-btn>
          <div class="conversation-notification" v-if="showRailNotification(route)">
            <v-icon size="16" color="success">mdi-circle</v-icon>
          </div>
        </div>
        <feature-toggle :permissionSet="PaymentsPermissionsSet">
          <div class="mt-2">
            <v-btn @click="routeToAuthVia" color="#00386F" target="_blank" text class="nav-button mt-6" active-class="active-nav-btn">
              <v-icon large class="white--text">mdi-currency-usd</v-icon>
            </v-btn>
          </div>
        </feature-toggle>
        <div class="help-btn-contain d-flex ml-5 mb-10">
          <support-tooltip></support-tooltip>
        </div>
      </div>
      <!---->
      <!--this is shown on larger screens/desktop-->
      <div :class="contentContainClass" v-if="$vuetify.breakpoint.mdAndUp">
        <router-view></router-view>
      </div>
      <!---->
      <!--this is shown on small and mobile screens-->
      <div class="content-contain" v-else>
        <router-view></router-view>
      </div>
      <!---->
    </div>
    <v-snackbar v-model="showSnackbar" :multi-line="true" :timeout="snackTimeout">
      {{ snackMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink"
               text
               v-bind="attrs"
               @click="showSnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import { RouteRecordPublic } from 'vue-router';
import { EventBus, Channels } from '@/eventbus';
import globalStore from '@/store';
import Header from '@/components/Header.vue';
import SupportTooltip from '@/components/SupportTooltip.vue';
import { allowedRailRoutes } from '@/router';
import { getRoles } from '@/services/acxess';
import { authenticateMerchant } from '@/services/platform-authVia';
import { stopMessageHub } from '@/services/signalr';
import { isNullOrEmpty } from '@/utils';
import FeatureToggle from './components/FeatureToggle.vue';
import { acxessFeatureAccess, acxessProductIds } from './models/acxess';
import { AuthViaAuthenticationScope } from './models/platform-authVia';

export default Vue.extend({
  name: 'app',
  components: { Header, SupportTooltip, FeatureToggle },
  data() {
    return {
      gstore: globalStore.state,
      drawer: true,
      showSnackbar: false,
      snackMessage: '',
      snackTimeout: 5000,
      notificationsEnabled: false,
      PaymentsPermissionsSet: {
        productId: acxessProductIds.Payments,
        accessLevels: [
          acxessFeatureAccess.View,
          acxessFeatureAccess.ViewAndChange,
          acxessFeatureAccess.ViewAndExecute,
          acxessFeatureAccess.ViewAndChangeAndExecute,
        ],
      },
    };
  },
  async created() {
    EventBus.$on(Channels.snackbarMessage, this.handleSnackbarMessage);
    // seed initial data
    globalStore.dispatch('auth/roles', { roles: await getRoles() });
  },
  async beforeDestroy() {
    EventBus.$off(Channels.snackbarMessage, this.handleSnackbarMessage);
    await stopMessageHub();
  },
  computed: {
    contentContainClass(): any {
      return {
        'content-contain': true,
        'rounded-tl-xl': true,
        'content-contain-with-nav': this.$route.meta?.showNav,
      };
    },
    userRoutes(): Array<RouteRecordPublic> {
      return allowedRailRoutes();
    },
  },
  methods: {
    handleSnackbarMessage(message: string): void {
      this.snackMessage = message;
      this.showSnackbar = true;
    },
    showRailNotification(route: any): boolean {
      const unreadMessagesExist = globalStore.getters['conversations/unreadGroupChatIds'].length > 0;
      const routeTitle = route != null && route.meta != null ? route.meta.pageTitle : '';
      const currentTitle = this.$route != null && this.$route.meta != null ? this.$route.meta.pageTitle : '';
      if (this.notificationsEnabled === false) {
        this.notificationsEnabled = this.$store.getters['app/appNotificationsEnabled'];
      }
      /* If...
        * 1) Notifications are enabled
        * 2) At least one unread message exists
        * 3) The input route leads to the Conversations page
        * 4) We're not on the Conversations page
        * Then we want to show the rail notification
        */

      if (this.notificationsEnabled && unreadMessagesExist && routeTitle.includes('Conversations') && !currentTitle.includes('Conversations')) {
        return true;
      }
      return false;
    },
    async routeToAuthVia(): Promise<void> {
      let authViaPortalUrl = 'https://beta-app.authvia.net';
      const token = await authenticateMerchant(AuthViaAuthenticationScope.PortalLogin);
      if (!isNullOrEmpty(token)) {
        authViaPortalUrl = `${authViaPortalUrl}/?t=${token}`;
      }
      window.open(authViaPortalUrl);
    },
  },
});
</script>

<style lang="scss" scoped>
  .conversation-notification {
    left: 48px;
    position: absolute;
    top: 19px;
  }

  .active-nav-btn {
    background-color: var(--v-primarybkg-base);
  }
</style>

<style lang="scss">
  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
  }

  .content-contain {
    z-index: 1;
    position: relative;
    overflow: hidden;
    min-height: 100%;
    background-color: var(--v-white-base);
    display: flex;
    flex: 1;
    color: var(--v-darkgray-base);
  }

  .content-contain-with-nav {
    margin-left: -5em;
  }

  .v-tab, .v-btn {
    text-transform: unset;
  }

  #app .nav-button {
    height: 3em;
    margin-left: 0.9em;
    width: 4em;
    min-width: unset;
  }

  .body-contain {
    flex: 1;
  }

  .help-btn-contain {
    align-items: flex-end;
    flex: 1;
    position: fixed;
    bottom: 0px;
    left: 0px;
  }

  .nav-contain {
    background-color: var(--v-primary-base);
    width: 11em;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(180deg, var(--v-primary-base) 50%, var(--v-primary-lighten1) 100%);
  }
  // vuetify overrides (no scss vars available)
  .v-application .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    color: var(--v-darkgray-darken4);
  }

  .view-title {
    margin-top: .5em;
    margin-left: 1em;
  }

  .divide-y > div {
    border-top: solid 1px var(--v-info-lighten5)
  }

  .divide-y > div:first-child {
    border-top: none;
  }

  .message-inbound {
    background-color: var(--v-graybkg-darken1);
    border-radius: 0 1rem 1rem 1rem;
    position: relative;
    display: block;
  }

  .message-outbound {
    background-color: var(--v-primary-base);
    color: var(--v-white-base);
    border-radius: 1rem 0 1rem 1rem;
    position: relative;
    display: block;
  }

  .message-outbound a {
    color: var(--v-success-base) !important;
  }

  .actions-column {
    white-space: nowrap;
  }

  .v-input__append-outer {
    align-self: flex-end !important;
    margin-bottom: 1.6em;
  }

  .list-unstyled {
    list-style: none;
  }

  .step-help-wrap {
    background-color: var(--v-graybkg-base);
    padding: 4em;
    border-left: solid 1px var(--v-grabkg-darken5);
    width: 100em;
  }
  // used to apply to all the overlay panel v-dialogs using 'content-class'.
  .overlay-override-class {
    height: 100% !important;
    max-height: 100% !important;
    margin: 0 !important;
    margin-left: 20% !important;
    border-radius: 0 !important;
  }

  .w-full-f {
    width: 100% !important;
  }
</style>
