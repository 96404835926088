<template>
  <v-btn icon class="beamerTrigger pa-3 ml-2" @click="beamerHasNewPosts = false">
    <v-icon v-if="beamerHasNewPosts" color="white">mdi-bell</v-icon>
    <v-icon v-else color="white">mdi-bell-outline</v-icon>
  </v-btn>
</template>

<script lang="ts">
import { logAcxessException, logType } from '@/services/logger';
import Vue from 'vue';

declare global {
  interface Window {
    Beamer: any;
  }
}

export default Vue.extend({
  name: 'Beamer',
  data() {
    return {
      beamerScript: null as HTMLScriptElement | null,
      beamerConfig: null as HTMLScriptElement | null,
      beamerHasNewPosts: false,
    };
  },
  mounted() {
    this.loadBeamer();
  },
  beforeDestroy() {
    this.removeBeamer();
  },
  methods: {
    loadBeamer() {
      if (!window.Beamer) {
        // Load beamer config script with defaults that won't change between logins. Only needed on first load per session.
        this.beamerConfig = document.createElement('script');
        this.beamerConfig.textContent = `
          var beamer_config = {
              product_id : "YMMTgNtH47261",
              mobile : false,
              force_filter : 'textel',
              multi_user : true,
              lazy: true,
              company_products : 'textel',
          };
        `;
        document.body.appendChild(this.beamerConfig);

        this.beamerScript = document.createElement('script');
        this.beamerScript.src = 'https://app.getbeamer.com/js/beamer-embed.js';
        this.beamerScript.async = true;
        this.beamerScript.onload = () => {
          this.beamerOnLoad();
        };
        document.body.appendChild(this.beamerScript);
      } else {
        // When switching users the Beamer script will stay in the DOM and needs to be updated for the current user info.
        this.updateBeamerInfo();
        window.Beamer.init();
      }
    },
    removeBeamer() {
      if (window.Beamer) {
        window.Beamer.destroy();
      }
      if (this.beamerConfig) {
        document.body.removeChild(this.beamerConfig);
        this.beamerConfig = null;
      }
      if (this.beamerScript) {
        document.body.removeChild(this.beamerScript);
        this.beamerScript = null;
      }
    },
    updateBeamerInfo() {
      window.Beamer.update({
        user_firstname: this.$store.getters['auth/session'].given_name,
        user_lastname: this.$store.getters['auth/session'].family_name,
        user_email: this.$store.getters['auth/session'].email,
        user_id: this.$store.getters['auth/session'].nameid,
        callback: (numberOfNewPosts: number) => {
          this.beamerHasNewPosts = numberOfNewPosts > 0;
        },
      });
    },
    beamerOnLoad() {
      if (window.Beamer) {
        this.updateBeamerInfo();
        window.Beamer.init();
      } else {
        logAcxessException(logType.Error, 'Beamer could not be loaded.');
      }
    },
  },
});

</script>
