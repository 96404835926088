<template>
  <v-menu :nudge-right="22" :close-on-content-click="false" :min-width="275" :nudge-top="230" offset-x>
    <template v-slot:activator="{ on,attrs }">
      <v-btn size="50" icon color="white" class="help-btn" v-on="on">
        <v-icon size="50" v-bind="attrs">mdi-help-circle-outline</v-icon>
      </v-btn>
    </template>
    <v-list dark>
      <h3 class="text-center">Support Topics</h3>
      <v-list-item><v-icon color="white">mdi-circle-small</v-icon><a href="https://support.textel.net/" target="_blank" class="text-decoration-underline" style="color: white"><v-list-item-title>Knowledge Base Home</v-list-item-title></a></v-list-item>
      <v-list-item><v-icon color="white">mdi-circle-small</v-icon><a href="https://support.textel.net/folder/58d8e95e-5c5e-4760-8102-cb8ef6c13981" target="_blank" class="text-decoration-underline" style="color: white"><v-list-item-title>Conversations</v-list-item-title></a></v-list-item>
      <v-list-item><v-icon color="white">mdi-circle-small</v-icon><a href="https://support.textel.net/folder/7527c474-04da-423d-8c1a-12f431f1832e" target="_blank" class="text-decoration-underline" style="color: white"><v-list-item-title>Responders</v-list-item-title></a></v-list-item>
      <v-list-item><v-icon color="white">mdi-circle-small</v-icon><a href="https://support.textel.net/folder/2f78e0a2-0e09-4557-8b24-5206d911d0f4" target="_blank" class="text-decoration-underline" style="color: white"><v-list-item-title>Contacts</v-list-item-title></a></v-list-item>
      <v-list-item><v-icon color="white" class="mr-2">mdi-message-outline</v-icon><v-list-item-title>Text Us @ 844-483-9835</v-list-item-title></v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'SupportTooltip',
});
</script>

<style lang="scss" scoped>
.help-btn {
  top: -1em;
}
</style>
