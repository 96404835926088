<template>
  <div class="header-contain d-flex">
    <router-link to="/">
      <!-- RingCentral -->
      <img v-if="getStyleVersion() == 2" src="../assets/ring_central_logo.svg"
        class="ring-logo mx-3 mt-3 pb-0" />
      <!-- Default -->
      <img v-else src="../assets/textel_bubble_inverse.svg"
        class="logo mx-3 mt-3 pb-2" />
    </router-link>
    <div class="d-flex menu-contain">
      <!--this is only shown for larger screens/desktop-->
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <div v-for="item in headerRoutes" :key="item.id">
          <!-- routes without parents i.e. no drop-down menu -->
          <v-btn v-if="item.route != null" :to="item.route.path || '/'" text class="white--text font-weight-bold subtitle-1 ml-10">
            {{item.route.meta.nav.header.title}}
          </v-btn>
          <v-menu v-else offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" text class="ml-10 white--text">
                <div class="flex-columns">
                  <div class="font-weight-bold subtitle-1 dd-menu-text">{{item.parent}}</div>
                  <v-icon class="ma-0 pa-0 dd-menu-caret">
                    mdi-menu-down
                  </v-icon>
                </div>
              </v-btn>
            </template>
            <v-list class="pa-3">
              <v-list-item v-for="child in item.children" :key="child.path" :to="child.path">
                {{child.meta.nav.header.title}}
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
      <!---->
    </div>
    <div class="d-flex pr-3 pt-5">
      <!--this is only shown for larger screens/desktop-->
      <div v-if="$vuetify.breakpoint.mdAndUp" class="d-flex">
        <profile-menu @logout="logout"></profile-menu>
        <beamer/>
      </div>
      <!---->
      <!--this is only shown for small and mobile screens-->
      <div v-else>
        <v-btn icon @click.stop="openNavMenu = !openNavMenu;">
          <v-icon color="white">mdi-menu</v-icon>
        </v-btn>
      </div>
      <!---->
    </div>
    <v-navigation-drawer v-model="openNavMenu"
                         absolute
                         temporary
                         >
      <navigation-menu :headerRoutes="headerRoutes"></navigation-menu>
    </v-navigation-drawer>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { RouteRecordPublic } from 'vue-router';
import { allowedHeaderRoutes } from '@/router';
import { clearServiceCache } from '@/services';
import { logout } from '@/services/acxess';
import Beamer from '@/components/Beamer.vue';
import NavigationMenu from '@/components/NavigationMenu.vue';
import ProfileMenu from '@/components/ProfileMenu.vue';
import { getStyleVersion } from '@/utils';

export default Vue.extend({
  components: { Beamer, NavigationMenu, ProfileMenu },
  name: 'Header',
  data() {
    return {
      headerRoutes: [] as any,
      openNavMenu: false,
    };
  },
  mounted() {
    // build up menu object for header
    const allowedRoutes = allowedHeaderRoutes();
    let rid = 1;
    for (let i = 0; i < allowedRoutes.length; i++) {
      if (allowedRoutes[i].meta.nav.header.parent == null) {
        // root-level route i.e. no parent
        this.headerRoutes.push({
          id: rid++,
          route: allowedRoutes[i],
        });
      }
      const { parent } = allowedRoutes[i].meta.nav.header;
      // does the parent already exist?
      let exists = false;
      for (let u = 0; u < this.headerRoutes.length; u++) {
        if (this.headerRoutes[u].parent === parent) {
          exists = true;
          break;
        }
      }
      if (!exists) {
        this.headerRoutes.push({
          id: rid++,
          parent,
          children: this.childRoutes(parent, allowedRoutes),
        });
      }
    }
  },
  methods: {
    getStyleVersion,
    async logout(): Promise<void> {
      const result = await logout();
      if (result.loggedOutSuccessfully) {
        clearServiceCache();
        this.$store.dispatch('auth/logout');
        this.$router.replace('/login');
      }
    },
    childRoutes(parent:string, allowedRoutes:Array<RouteRecordPublic>):Array<RouteRecordPublic> {
      const result:Array<RouteRecordPublic> = [];
      for (let i = 0; i < allowedRoutes.length; i++) {
        if (allowedRoutes[i].meta.nav.header.parent === parent) {
          result.push(allowedRoutes[i]);
        }
      }
      return result;
    },
  },
});
</script>

<style scoped lang="scss">
.logo {
    width:5.4em;
    border-bottom: solid 1px black;
}
.ring-logo {
    width: 4.9em;
    height: 4.9em;
}
.header-contain {
    background-color: var(--v-primary-base);
    z-index: 2;
    width:100%;
}
.menu-contain {
    width:100%;
    align-items: center;
    justify-content: flex-end;
}
.dd-menu-text {
    top: 0.8em;
    position: relative;
}
.dd-menu-caret {
    top: .1em;
}
</style>
