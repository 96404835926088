<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs"
             v-on="on"
             elevation="0"
             class="primary lighten-1 ml-10">
        <v-icon class="mr-4">
          mdi-account-circle
        </v-icon>
        {{$store.getters["auth/session"].email}}
      </v-btn>
    </template>
    <v-list class="pa-3">
      <v-list-item to="/my-profile">
        My Profile
      </v-list-item>
      <feature-toggle route="/users">
        <v-list-item to="/users">
          User Management
        </v-list-item>
      </feature-toggle>
      <feature-toggle route="/link-management">
        <v-list-item to="/link-management">
          Link Management
        </v-list-item>
      </feature-toggle>
      <feature-toggle route="/manage-account">
        <v-list-item to="/manage-account">
          Manage Account
        </v-list-item>
      </feature-toggle>
      <v-list-item @click.prevent="$emit('logout')">
        Logout
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import Vue from 'vue';
import FeatureToggle from './FeatureToggle.vue';

export default Vue.extend({
  name: 'ProfileMenu',
  components: { FeatureToggle },
});
</script>
