<template>
  <div>
    <v-list nav dense>
      <div v-for="item in allowedRoutes" :key="item.id">
        <v-list-item v-if="item.route != null" :to="routePath(item) || '/'">
          <v-list-item-title>{{ routeTitle(item) }}</v-list-item-title>
          <v-list-item-icon><v-icon color="primary">{{ routeIcon(item) }}</v-icon></v-list-item-icon>
        </v-list-item>
        <v-list-group v-else>
          <template v-slot:activator>
            <v-list-item-title>{{item.parent}}</v-list-item-title>
          </template>
          <v-list class="pa-3">
            <v-list-item v-for="child in item.children" :key="child.path" :to="child.path">
              <v-list-item-title>{{child.meta.nav.header.title}}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-list-group>
      </div>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-title>My Profile</v-list-item-title>
        </template>
        <v-list class="pa-3">
          <v-list-item to="/my-profile">
            <v-list-item-title>My Profile</v-list-item-title>
          </v-list-item>
          <feature-toggle route="/users">
            <v-list-item to="/users">
              <v-list-item-title>User Management</v-list-item-title>
            </v-list-item>
          </feature-toggle>
          <feature-toggle route="/link-management">
            <v-list-item to="/link-management">
              Link Management
            </v-list-item>
          </feature-toggle>
          <feature-toggle route="/manage-account">
            <v-list-item to="/manage-account">
              <v-list-item-title>Manage Account</v-list-item-title>
            </v-list-item>
          </feature-toggle>
          <v-list-item @click.prevent="logout">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-list-group>
      <feature-toggle :permissionSet="PaymentsPermissionsSet">
        <v-list-item href="https://app.authvia.net/" target="_blank">
          <v-list-item-title>Payments</v-list-item-title>
          <v-list-item-icon><v-icon color="primary">mdi-currency-usd</v-icon></v-list-item-icon>
        </v-list-item>
      </feature-toggle>
      <v-list-item href="https://support.textel.net/" target="_blank">
        <v-list-item-title>Knowledge Base Home</v-list-item-title>
        <v-list-item-icon><v-icon color="primary">mdi-help-circle-outline</v-icon></v-list-item-icon>
      </v-list-item>
    </v-list>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { allowedRailRoutes } from '@/router';
import { clearServiceCache } from '@/services';
import { logout } from '@/services/acxess';
import { acxessFeatureAccess, acxessProductIds } from '@/models/acxess';
import FeatureToggle from './FeatureToggle.vue';

export default Vue.extend({
  name: 'NavigationMenu',
  components: { FeatureToggle },
  props: {
    headerRoutes: {
      type: Array as PropType<Array<any>>,
    },
  },
  data() {
    return {
      allowedRoutes: [] as Array<any>,
      drawer: false,
      PaymentsPermissionsSet: {
        productId: acxessProductIds.Payments,
        accessLevels: [
          acxessFeatureAccess.View,
          acxessFeatureAccess.ViewAndChange,
          acxessFeatureAccess.ViewAndExecute,
          acxessFeatureAccess.ViewAndChangeAndExecute,
        ],
      },
    };
  },
  async mounted() {
    const railRoutes = await allowedRailRoutes();
    let routeId = this.headerRoutes.length + 1;
    for (let i = 0; i < railRoutes.length; i++) {
      this.allowedRoutes.push({
        id: routeId++,
        route: railRoutes[i],
      });
    }
    this.allowedRoutes = this.allowedRoutes.concat([...this.headerRoutes]);
  },
  methods: {
    routeTitle(route: any): string {
      if (route.route.meta.nav.header != null) {
        return route.route.meta.nav.header.title;
      }
      return route.route.meta.nav.rail.title;
    },
    routePath(route: any): string {
      if (route.route != null) {
        return route.route.path;
      }
      return '/';
    },
    routeIcon(route: any): string {
      if (route.route.meta.nav.header != null) {
        return route.route.meta.nav.header.icon;
      }
      return route.route.meta.nav.rail.icon;
    },
    async logout(): Promise<void> {
      const result = await logout();
      if (result.loggedOutSuccessfully) {
        clearServiceCache();
        this.$store.dispatch('auth/logout');
        this.$router.replace('/login');
      }
    },
  },
});
</script>
