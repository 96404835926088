<template>
  <div>
    <img :class="iconColor" v-if="name === 'capacity-icon'" src="../assets/capacity_logo.svg" />
  </div>
</template>

<script lang="ts">
import { StyleVersion } from '@/models/style-version';
import { getStyleVersion } from '@/utils';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'IconComponent',
  props: {
    name: {
      type: String as PropType<string>,
      default: null,
    },
  },
  computed: {
    // allows us to change the svg fill color
    iconColor(): string {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return 'icon-white';
      }

      // determine color for white labeling
      if (getStyleVersion() === StyleVersion.RingCentral) {
        return 'icon-ringcentral';
      }

      // default color of #005eb8 (primary)
      return '';
    },
  },
});
</script>

<style scoped lang="scss">
.icon-white {
  filter: brightness(0) invert(1);
}
.icon-ringcentral {
  // only way to change colors is with filters
  // used a filter calculator to get the values for the ringcentral blue color
  // https://angel-rs.github.io/css-color-filter-generator/
  filter: brightness(0) saturate(100%) invert(44%) sepia(58%) saturate(5195%) hue-rotate(175deg) brightness(88%) contrast(95%);
}
</style>
